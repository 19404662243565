
import { defineComponent, inject, PropType, ref } from "vue";
import { mapGetters, mapActions, mapState, useStore } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Dropdown from "primevue/dropdown";
import Tooltip from "primevue/tooltip";
import Badge from "primevue/badge";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import ContextMenu from "primevue/contextmenu";
import PrinterSelectionDialog from "@/components/Pos/PrinterSelectionDialog.vue";
import InvoiceService from "@/services/InvoiceService";
import Utils from "@/utility/utils";
import GetAccountsReceivableRequest, {
  AccountsReceivableAgingTypes,
  ARSortTypes,
} from "@/types/services/accountsReceivable";
import { useFetchArRecords } from "@/composables/AR/useFetchAccountReceivables";
import ARService from "@/services/ARService";
import { useLazyLoadPagination } from "@/composables/DataTable/useLazyLoadPagination";
import { useNotifications } from "@/composables/Notification/useNotifications";
import _ from "lodash";
import { MenuItem } from "primevue/menuitem";

const arService = new ARService();

export default defineComponent({
  components: {
    DataTable,
    Column,
    Button,
    InputText,
    Calendar,
    Row,
    ColumnGroup,
    Badge,
    EmailFileDialog,
    Dropdown,
    ContextMenu,
    PrinterSelectionDialog,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    tableName: {
      type: String,
    },
    isCustomTab: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Object,
      default: () => ({}) as any,
    },
    showType: {
      type: Boolean,
      default: true,
    },
    showToPay: {
      type: Boolean,
      default: true,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    forceFetch: {
      type: Boolean,
      default: false,
    },
    loadOnCreate: {
      type: Boolean,
      default: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    hidePoNo: {
      type: Boolean,
      default: true,
    },
    noDefaultSort: {
      type: Boolean,
      default: false,
    },
    agingTypeOverride: {
      type: String as PropType<AccountsReceivableAgingTypes>,
      required: false,
    },
    showInvoicePrintAction: {
      type: Boolean,
      default: false,
    },
    rowsPerPage: {
      type: Number,
      default: 5,
    },
    defaultFilters: {
      type: Object,
    },
  },
  emits: ["rowClick", "update:forceFetch"],
  setup(props) {
    const custId = inject<any>("customerId");
    const dateRange = ref<any>();
    const emptyTableLabel = ref("Invoices have not been loaded.");
    const filters = ref({
      ar_id: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: props.defaultFilters?.ar_id || null,
            matchMode: FilterMatchMode.CONTAINS,
          },
        ],
      },
      cust_name: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: props.defaultFilters?.cust_name || null,
            matchMode: FilterMatchMode.CONTAINS,
          },
        ],
      },
      status: {
        value: props.defaultFilters?.status || "",
        matchMode: FilterMatchMode.IN,
      },
      type: {
        value: props.defaultFilters?.type || "",
        matchMode: FilterMatchMode.EQUALS,
      },
    });
    const store = useStore();
    const {
      addSuccessNotification,
      addWarnNotification,
      addErrorNotification,
    } = useNotifications(store);
    const { fetchArRecords } = useFetchArRecords(arService);
    const agingType = props.agingTypeOverride
      ? props.agingTypeOverride
      : inject("accountsReceivableAgingType");

    const getReceivables = async (isReset = false) => {
      if (isReset) {
        records.value = [];
      }
      const rangeStart = records.value.length + 1;
      const rangeEnd = records.value.length + 100;
      const request = {
        statuses: [filters.value.status.value],
        Client: store.getters["session/getClient"],
        types: [filters.value.type.value],
        correls: "cust_name",
        rangeStart,
        rangeEnd,
      } as Partial<GetAccountsReceivableRequest>;
      if (custId) {
        request.cust = custId.value;
      }
      let [dateStart, dateEnd] = dateRange.value || [];
      if (dateStart) {
        dateStart = Utils.formatDate(dateStart);
      }
      if (dateEnd) {
        dateEnd = Utils.formatDate(dateEnd);
      }

      if ((agingType as any) === AccountsReceivableAgingTypes.DueDate) {
        request.dueDateStart = dateStart;
        request.dueDateEnd = dateEnd;
        request.sortBy = ARSortTypes.DueDate;
      } else if (
        (agingType as any) === AccountsReceivableAgingTypes.RegisterDate
      ) {
        request.registerDateStart = dateStart;
        request.registerDateEnd = dateEnd;
        request.sortBy = ARSortTypes.RegisterDate;
      } else {
        request.invoiceDateStart = dateStart;
        request.invoiceDateEnd = dateEnd;
        request.sortBy = ARSortTypes.InvoiceDate;
      }

      if (props.noDefaultSort) {
        request.sortBy = undefined;
      }

      try {
        isLoading.value = true;
        const resp = await fetchArRecords(request);
        totalRecords.value = resp.total_records_found;
        records.value.push(...resp.ar_items);
      } catch (error) {
        addErrorNotification("Failed to fetch receivables. Please try again.");
      } finally {
        isLoading.value = false;
        emptyTableLabel.value = "No receivables found.";
      }
    };

    const { records, totalRecords, isLoading, first, rowsPerPage, onPage } =
      useLazyLoadPagination(() => getReceivables(), props.rowsPerPage);
    const localRowsPerPage = rowsPerPage;
    return {
      fetchArRecords,
      first,
      localRowsPerPage,
      isLoading,
      onPage,
      records,
      totalRecords,
      getReceivables,
      dateRange,
      agingType,
      filters,
      addSuccessNotification,
      addWarnNotification,
      addErrorNotification,
      custId,
      emptyTableLabel,
    };
  },
  data() {
    return {
      showEmailInvoiceDialog: false,
      showHostInvoicePrintDialog: false,
      currentInvoiceId: "",
      contextItems: [] as any,
      types: [
        { name: "Credit Memo", code: "CM" },
        { name: "Debit Memo", code: "DM" },
        { name: "Invoice", code: "IN" },
        { name: "On Account", code: "OA" },
      ],
      stati: [
        { name: "Outstanding", code: "O" },
        { name: "Paid", code: "P" },
        { name: "Payment Pending", code: "Y" },
      ],
      hoverPayBottonIndex: -1,
      hover: [],
      invoiceService: new InvoiceService(),
    };
  },
  async created() {
    this.dateRange = this.range ? this.range.range : null;
    if (this.loadOnCreate) {
      this.getReceivables();
    }
  },
  computed: {
    ...mapState(["accountingReceivables"]),
    ...mapGetters({
      getClient: "session/getClient",
      getReceivablesByLabel: "accountingReceivables/getReceivablesByLabel",
      getInvoice: "accountingReceivables/getInvoice",
      loadingPDFInvoices: "accountingReceivables/getLoadingInvoices",
      invoicesToPay: "invoice/getInvoicesToPay",
      getAccess: "session/getAccess",
      getDefaultPickTicketPrinter: "pos/getDefaultPickTicketPrinter",
    }),
    columnCount(): number {
      let count = this.custId ? 8 : 9;
      if (!this.showType) {
        count--;
      }
      if (!this.showInvoiceAmountColumn) {
        count--;
      }
      return count;
    },
    getTotalBalance(): string {
      let total = 0.0;
      if (!this.records || !this.records.length)
        return this.formatCurrency(total);
      this.records.forEach((invoice: any) => {
        total += parseFloat(invoice.balance ?? "0.00");
      });
      return this.formatCurrency(total);
    },
    tableColumnHeaderClass(): string {
      return !this.compact ? "font-bold text-base" : "";
    },
    tableAmountColumnBodyClass(): string {
      return !this.compact
        ? "font-semibold text-right pr-2 lg:pr-3 w-2"
        : "text-right";
    },
    textBaseClass(): string {
      return !this.compact ? "text-base" : "";
    },
    currencyClass(): string {
      return !this.compact ? "text-base text-right" : "";
    },
    showInvoiceAmountColumn(): boolean {
      return this.records.some((ar: any) => ar.invoice_amt);
    },
  },
  methods: {
    ...mapActions({
      addIsLoading: "accountingReceivables/addIsLoading",
      updateCustomDateRange: "accountingReceivables/updateCustomDateRange",
      addPDFLoading: "accountingReceivables/addIdLoading",
      removeIdLoading: "accountingReceivables/removeIdLoading",
      postInvoiceToPay: "invoice/addInvoiceToPay",
      removeInvoiceToPay: "invoice/removeInvoiceToPay",
      setDefaultPickTicketPrinter: "pos/setDefaultPickTicketPrinter",
    }),
    handlePrintInvoice(data: any) {
      this.showHostInvoicePrintDialog = false;
      //set the default printer if option is selected
      if (data.saveDefaultPrinter) {
        //we may want to support multiple default printers in the future
        this.setDefaultPickTicketPrinter(data.selectedPrinter);
      }
      //print the invoice.
      this.invoiceService.printInvoice(
        this.currentInvoiceId,
        this.getClient,
        data.selectedPrinter,
      );
    },
    openMenu(event: any, rowData: any) {
      // Define context menu options based on the selected row data
      this.contextItems = this.getSpeedDialActions(rowData);

      // Programmatically open the ContextMenu
      (this.$refs.menu as any).toggle(event);
    },
    viewDetails(rowData: any) {
      alert(`Viewing details for ${rowData.name}`);
    },
    deleteRow(rowData: any) {
      alert(`Deleting ${rowData.name}`);
    },
    async handleClearFilterByStatus(): Promise<void> {
      this.filters.status.value = "";
      this.getReceivables(true);
    },
    async handleClearFilterByType(): Promise<void> {
      this.filters.type.value = "";
      this.getReceivables(true);
    },
    async handleFilter(): Promise<void> {
      this.getReceivables(true);
    },
    isSelectedRow(data: any) {
      return (
        this.selectedItems.find((item) => item === data.ar_id) && "bg-blue-100"
      );
    },
    handleHideCalendar(event: any) {
      if (!event[0] || event[1] === null) return;
      this.updateCustomDateRange({
        label: this.range ? this.range.label : "",
        range: this.dateRange,
      });

      this.getReceivables(true);
    },
    formatCurrency(value: number) {
      let number = typeof value === "string" ? parseFloat(value) : value;
      return `$${number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    },
    onRowSelect(data: any) {
      this.$emit("rowClick", data);
    },
    getTypeTitle(type: string): string {
      return this.types.find((typ: any) => typ.code === type)?.name ?? "";
    },
    getSpeedDialActions(data: any): MenuItem[] {
      const actions: MenuItem[] = [
        {
          label: "Download",
          icon: this.invoiceIcon(data.ar_id),
          command: () => {
            this.fetchPDFInvoice(data.ar_id);
          },
        },
        {
          label: "Email",
          icon: "pi pi-envelope",
          command: () => {
            this.showEmailModal(data.ar_id);
          },
        },
        ...(this.showInvoicePrintAction
          ? [
              {
                label: "Print",
                icon: "pi pi-print",
                command: () => {
                  this.showHostInvoicePrintModal(data.ar_id);
                },
              },
            ]
          : []),
      ];
      return actions;
    },
    invoiceIcon(invoiceId: string) {
      let downloading = this.loadingPDFInvoices.includes(invoiceId);
      if (downloading) {
        return "pi pi-spin pi-spinner";
      } else {
        return "pi pi-download";
      }
    },

    fetchPDFInvoice(invoiceId: string) {
      this.addPDFLoading(invoiceId);

      this.invoiceService
        .getInvoice(invoiceId)
        .then((response: any) => {
          const bufferArray = Utils.base64ToArrayBuffer(response);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);

          this.removeIdLoading(invoiceId);

          window.open(data, "_blank");
        })
        .catch(() => {
          this.addErrorNotification(
            "Failed to download invoice preview. Please try again.",
          );

          this.removeIdLoading(invoiceId);
        });
    },
    showEmailModal(id: string) {
      this.showEmailInvoiceDialog = true;
      this.currentInvoiceId = id;
    },
    showHostInvoicePrintModal(id: string) {
      this.showHostInvoicePrintDialog = true;
      this.currentInvoiceId = id;
    },
    sendEmail(data: any) {
      this.invoiceService
        .getInvoice(this.currentInvoiceId, data)
        .then((response: any) => {
          if (response === "success") {
            this.addSuccessNotification(
              `Invoice #${this.currentInvoiceId} has been emailed successfully`,
            );
          } else {
            this.addErrorNotification("Invoice was not sent");
          }
        })
        .catch((error) => {
          this.addErrorNotification(`Invoice could not be sent: ${error}`);
        })
        .catch((error) => {
          this.addErrorNotification(`Invoice could not be sent: ${error}`);
        });
    },
    existsInPayArray(invoice: any) {
      const found = this.invoicesToPay.find((inv: any) => {
        return inv.arId == invoice.ar_id;
      });
      if (found) return true;

      return false;
    },
    addInvoiceToPay(invoice: any) {
      this.postInvoiceToPay({
        ...invoice,
        arId: invoice.ar_id,
        balance: parseFloat(invoice.balance),
        dueDate: invoice.due_date,
        cust: invoice.cust,
      });

      this.addSuccessNotification(
        `Invoice #${invoice.ar_id} added to payment.`,
      );
    },
    removeInvoice(data: any): void {
      this.removeInvoiceToPay({
        arId: data.ar_id,
        balance: parseFloat(data.balance),
      });

      this.addWarnNotification(`Invoice #${data.ar_id} removed from payment.`);
    },
    formatStringDate(dueDate: any) {
      return Utils.formatDate(dueDate, !this.compact);
    },
  },
  watch: {
    async range(val: any, oldVal: any) {
      if (_.isEqual(val, oldVal)) return;
      this.dateRange = val.range || val;
      if (!this.isCustomTab) {
        this.getReceivables(true);
      }
    },
    async forceFetch(val: boolean) {
      if (val && !this.isLoading) {
        this.getReceivables(true);
        this.$emit("update:forceFetch", false);
      }
    },
    async custId(val: string) {
      if (val && !this.isLoading) {
        this.getReceivables(true);
      }
    },
  },
});
